import { RechargeAnyValueId } from "~/enums/siteEnum";

export const state = () => ({
  info: {},
  projectId: '',
  merchantId: '',
  merchantInfo: {},
  domain: '',
  siteInfo: {},
  currency: [],
  currencieMap: {},
  rechargeStatic: {},
  rechargeActivity: {
    percent: 0
  },
  rechargeValueList: [],
  serverList: [],
  vipConfig: null
})

export const actions = {
  async getRechargeValues(root) {
    const params = {
      project_id: root.state.projectId
    }
    const res = await this.$api.project.getRechargeList(params)
    if (res?.list) {
      const data = res.list.map(item => {
        const newItem = {...item}
        newItem.label = newItem.name
        newItem.value = String(newItem.id)
        return newItem
      })
      data.unshift({
        id: RechargeAnyValueId,
        label: '任意面额',
        value: RechargeAnyValueId
      })
      root.commit('SET_RECHARGE_VALUE_LIST', data)
    }
  },
  async getVipConfigInfo(root) {
    const [err, res] = await this.$utils.to(this.$api.vip.getVipConfig({ merchant_id: root.state.merchantId }))
    if (!err) {
      root.commit('SET_VIP_CONFIG', res)
    }
  },
  async getCurrencyList(root) {
    const res = await this.$api.common.getCurrencies()
    const currencieMap = {}
    res.data.forEach((c) => {
      currencieMap[c.id] = c
    })
    root.commit('SET_CURRENCYINFO', {
      currency: res.data,
      currencieMap
    })
  },
  async getServerList(root) {
    if (root.state.serverList.length) return
    const [err, res] = await this.$utils.to(this.$api.siteBuild.getServerList(root.state.projectId))
    if (!err && res.servers) {
      const data = res.servers.map(item => {
        const newItem = {...item}
        newItem.label = newItem.name
        newItem.value = newItem.id
        return newItem
      })
      root.commit('SET_SERVER_LIST', data)
    }
  }
}

export const mutations = {
  SET_RECHARGE_VALUE_LIST(state, data) {
    state.rechargeValueList = data
  },
  SET_SERVER_LIST(state, data) {
    state.serverList = data
  },
  SET_VIP_CONFIG(state, data) {
    state.vipConfig = data
  },
  SET_INFO(state, info) {
    if(!info){
      return
    }
    state.info = info
    state.projectId = info.project_id || ''
    state.merchantId = info.merchant_id || ''
    state.domain = info.domain || ''
  },
  SET_RECHARGE_STATIC(state, data) {
    state.rechargeStatic = data
  },
  SET_RECHARGE_ACTIVITY(state, data) {
    state.rechargeActivity = data
  },
  SET_MERCHANTINFO(state, info) {
    if(!info) {
      return
    }
    state.merchantInfo = info
  },
  UPDATE_SITEINFO(state, payload) {
    state.siteInfo = payload
  },
  SET_CURRENCYINFO (state, params) {
    state.currency = params.currency;
    state.currencieMap = params.currencieMap;
  }
}
