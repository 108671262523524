import {PageEnum} from '~/enums/pageEnum'
import {TokenName, SiteTokenName} from '~/config/cookieKeys'
import {isBuildWebSite} from '~/config'
import {getNeedParamsFormRouteParams} from '~/utils/index'

function getSiteTokenNameByContext(ctx) {
  const route = ctx.$route || ctx.route
  if (isBuildWebSite) {
    return SiteTokenName
  } else {
    const {path, params} = route
    if (path.indexOf(PageEnum.SITE_PREVIEW) === 0) {
      const domain = getNeedParamsFormRouteParams(params).sid
      return domain + '_' + SiteTokenName
    }
  }
  return SiteTokenName
}
export default {
  /*
  * 设置 商城登录信息
  * */
  setSiteAuthInfo(ctx, loginUser) {
    const $store = ctx.$store || ctx.store
    if ($store) {
      $store.commit('user/SET_SITE_LOGIN_USER', loginUser)
    }
  },
  siteUserLoginOut(ctx) {
    const $cookies = ctx.$cookies || ctx.app.$cookies
    const tokenName = getSiteTokenNameByContext(ctx)
    const $store = ctx.store || ctx.$store
    try{
      $store.commit('user/CLEAR_SITE_LOGIN_USER')
      $store.commit('goods/CLEAR_SHOP_CART')
      $cookies.remove(tokenName) // , { expires }
    }
    catch (e) {
      console.warn('siteUserLoginOut catch',e)
    }

  },
  clearToken(ctx) {
    const $cookies = ctx.$cookies || ctx.app.$cookies
    const tokenName = getSiteTokenNameByContext(ctx)
    $cookies.remove(tokenName)
  },
  setSiteToken(ctx, token) {
    const $cookies = ctx.$cookies || ctx.app.$cookies
    const tokeName = getSiteTokenNameByContext(ctx)
    $cookies.set(tokeName, token) // , { expires }
  },
  getSiteToken(ctx) {
    const $cookies = ctx.$cookies || ctx.app.$cookies
    const tokeName = getSiteTokenNameByContext(ctx)
    return $cookies.get(tokeName) // , { expires }
  },
  /**
   * 获取token
   * @param {object} ctx - 服务端传入context 客户端传入this
   * @param {string} token - token
   */
  getToken(ctx) {
    const {$cookies} = ctx || ctx.app
    if ($cookies) {
      return $cookies.get(TokenName) // , { expires }
    }
  },
  /**
   * 设置token'
   * @param {object} ctx - 服务端传入context 客户端传入this
   * @param {string} token - token
   */
  setToken(ctx, token) {
    const $utils = ctx.$utils || ctx.app.$utils
    const {$cookies, $store} = $utils.getAppStoreCookies(ctx)
    if ($cookies && $store) {
      $cookies.set(TokenName, token) // , { expires }
    }
  },
  /**
   * 获取store cookie
   * @param {object} ctx - 服务端传入context 客户端传入this
   */
  getAppStoreCookies(ctx) {
    // const $cookies, $store, $utils, $api
    const $cookies = ctx.$cookies || ctx.app.$cookies
    const $store = ctx.$store || ctx.store
    const $utils = ctx.$utils || ctx.app.$utils
    const $api = ctx.$api || ctx.app.$api
    // // 客户端
    // if (process.client) {
    //   $cookies = ctx.$cookies
    //   $store = ctx.$store
    //   $utils = ctx.$utils
    //   $api = ctx.$api
    // }
    // // 服务端
    // if (process.server) {
    //   $cookies = ctx.app.$cookies
    //   $store = ctx.store
    //   $utils = ctx.app.$utils
    //   $api = ctx.app.$api
    // }
    return {
      $cookies,
      $store,
      $utils,
      $api,
    }
  },
  /**
   * 设置登录验证信息
   * @param {object} ctx - 服务端传入context 客户端传入this
   * @param {object} res - 登录验证信息
   */
  setAuthInfo(ctx, res) {
    const $utils = ctx.$utils || ctx.app.$utils
    const {$cookies, $store} = $utils.getAppStoreCookies(ctx)
    if ($cookies && $store) {
      // const expires = 1200000
      $cookies.set('userId', res.user.id,) // { expires }
      $cookies.set('userInfo', res.user)  //, { expires }
      $store.commit('user/SET_USER', res.user)
      $store.commit('user/SET_TOKEN', res.token)
    }
  },
  getAuthUser(ctx) {
    const $utils = ctx.$utils || ctx.app.$utils
    const {$cookies} = $utils.getAppStoreCookies(ctx)
    return $cookies && $cookies.get('userInfo')
  },
  /**
   * 移除登录验证信息
   * @param {object} ctx - 服务端传入context 客户端传入this
   */
  removeAuthInfo(ctx) {
    const $utils = ctx.$utils || ctx.app.$utils
    // 客户端
    const {$cookies, $store} = $utils.getAppStoreCookies(ctx)
    if ($cookies && $store) {
      $cookies.remove('userInfo')
      $cookies.remove(TokenName)
      $cookies.remove('userId')
      $store.commit('user/SET_USER', null)
      $store.commit('user/SET_TOKEN', '')
    }
  },
  /**
   * 退出登录
   * @param {object} ctx - 服务端传入context 客户端传入this
   */
  loginOut(ctx) {
    if (ctx?.redirect) {
      const {redirect} = ctx
      redirect(PageEnum.BASE_LOGIN)
    } else if (ctx?.$router) {
      ctx.$router.push(PageEnum.BASE_LOGIN)
    } else {
      window && (window.location.href = PageEnum.BASE_LOGIN)
    }
  },
  to(promise, errorExt) {
    return promise
      .then((data) => [null, data])
      .catch((err) => {
        if (errorExt) {
          const parsedError = Object.assign({}, err, errorExt)
          return [parsedError, undefined]
        }
        return [err, undefined]
      })
  },
  copyText(text) {
    const type = "text/plain";
    const blob = new Blob([text], { type });
    // eslint-disable-next-line no-undef
    const data = [new ClipboardItem({ [type]: blob })];
    return navigator.clipboard.write(data)
  }
}
