const Api =  {
  rankList: '/api/v2/merchant-vip/vip/level/list',
  vipUserInfo: '/api/v2/merchant-vip/customer/info',
  pointGoods: '/api/v2/merchant-vip/points-mall/product/list',
  vipConfig: '/api/v2/merchant-vip/conf/merchant/settings/detail',
  pointExchange: '/api/v2/merchant-vip/points-mall/product/redeem',
  pointLog: '/api/v2/merchant-vip/customer/points/log/list',
  growthLog: '/api/v2/merchant-vip/customer/growth/log/list'
}

export default (request) => {
  return {
    getVipRankList: (params) => {
      return request.get(Api.rankList, { params })
    },
    getVipUserInfo: (params) =>  request.get(Api.vipUserInfo, { params }),
    getVipPointGoods: (params) => request.get(Api.pointGoods, { params }),
    getVipConfig: (params) => request.get(Api.vipConfig, { params }),
    exchangePoint: (params) => request.post(Api.pointExchange, params),
    getPointLog: (params) => request.get(Api.pointLog, { params }),
    growthLog: (params) => request.get(Api.growthLog, { params })
  }
}
