export const BackgroundSizeEnum = {
  // 填充
  COVER: 'cover',
  // 适应
  CONTAIN: 'contain',
  // 平铺
  REPEAT: 'repeat',
  // 拉伸
  STRETCH: '100% 100%',
}

export const DefaultBackgroundSize = BackgroundSizeEnum.COVER

export const BackgroundSizeList = [
  BackgroundSizeEnum.COVER,
  BackgroundSizeEnum.CONTAIN,
  BackgroundSizeEnum.STRETCH,
  BackgroundSizeEnum.REPEAT,
]

export const DefaultBackground = {
  backgroundColor: '',
  backgroundColorShow: true,
  backgroundImage: '',
  backgroundImageShow: true,
  backgroundSize: '',
  backgroundOpacity: 1
}
